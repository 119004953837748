<template>
	<div class="connent_title">
		<div class="connent_header">
			<span><a href="/ECharts/Demo/List">图表展示</a></span>
		</div>
		<el-link type="danger" target="_blank" :underline="false" href="https://echarts.apache.org/examples/zh/index.html">更多图表</el-link>
	</div>
	<br>
	<div style="width:100%;height:90%">
		<v-chart  :option="option"/>
	</div>
</template>

<script>

import echarts from "echarts";
import ECharts from "vue-echarts";
export default {
	components: {
		'v-chart': ECharts,
	},

	setup () {

		let option =  {
			xAxis: {
				type: 'category',
				data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']
			},
			title: {
				text: "折线图",
				left: "center"
			},
			yAxis: {
				type: 'value'
			},
			series: [
				{
					data: [150, 230, 224, 218, 135, 147, 260],
					type: 'line'
				}
			]
		};

		return {
			option
		}
	}
}
</script>